import React, { Component } from 'react';
import * as Scroll from 'react-scroll';
import { FormattedMessage } from 'react-intl';
import styles from './style.module.css';

let Link = Scroll.Link;

class Header extends Component {
    render() {
        return (
            <div
                className={this.props.pinHeader ? [styles.featuresListContainer, styles.fixed].join(' ') : [styles.featuresListContainer, styles.absolute].join(' ')}
                style={{paddingBottom: 0}}
            >
                <div className={styles.featuresListWrapper}>
                    <div className={styles.featuresList}>
                        <div className={[styles.header, styles.after].join(' ')}>
                            <Link
                                activeClass={styles.active}
                                to="setup" 
                                spy={true}
                                smooth={true}
                                offset={-150}
                                duration={500}
                            >
                                <span><FormattedMessage id="featuresHeader.setup" defaultMessage="Setup" /></span>
                            </Link>
                        </div>
                    </div>
                    <div className={styles.featuresList}>
                        <div className={[styles.header, styles.before, styles.after].join(' ')}>
                            <Link
                                activeClass={styles.active}
                                to="manage"
                                spy={true}
                                smooth={true}
                                offset={-150}
                                duration={500}
                            >
                                <span><FormattedMessage id="featuresHeader.manage" defaultMessage="Manage" /></span>
                            </Link>
                        </div>
                    </div>
                    <div className={styles.featuresList}>
                        <div className={[styles.header, styles.before, styles.after].join(' ')}>
                            <Link
                                activeClass={styles.active}
                                to="tuning"
                                spy={true}
                                smooth={true}
                                offset={-150}
                                duration={500}
                            >
                                <span><FormattedMessage id="featuresHeader.tuning" defaultMessage="Tuning" /></span>
                            </Link>
                        </div>
                    </div>
                    <div className={styles.featuresList}>
                        <div className={[styles.header, styles.before].join(' ')}>
                            <Link
                                activeClass={styles.active}
                                to="more"
                                spy={true}
                                smooth={true}
                                offset={-150}
                                duration={500}
                            >
                                <span><FormattedMessage id="featuresHeader.more" defaultMessage="More" /></span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Header;
