import React from 'react'
import { FormattedMessage } from 'react-intl'
import Link from 'components/Link';
import styles from './style.module.css';
import Header from './header';
import WL from 'constants/whiteLabel';

class FeaturesChain extends React.Component {
  render () {
    return (
      <div className={styles.container}>
        <Header pinHeader={this.props.pinHeader} />
        <div className={styles.featuresListContainer}>
          <div className={styles.featuresList}>
            <div className={styles.content}>
              <Link withLang to="/features#easyInstall"><FormattedMessage id="featuresChain.setup1" defaultMessage="Easy Installation" /></Link>
              <Link withLang to="/features#asicSetup"><FormattedMessage id="featuresChain.setup2" defaultMessage="ASIC Setup" /></Link>
              <Link withLang to="/features#gpuSetup"><FormattedMessage id="featuresChain.setup3" defaultMessage="GPU Setup" /></Link>
              <Link withLang to="/features#workersBulk"><FormattedMessage id="featuresChain.setup4" defaultMessage="Workers Bulk Installation" /></Link>
              {WL.showApi ? <Link withLang to="/features#deployPxe"><FormattedMessage id="featuresChain.setup5" defaultMessage="Deploy PXE" /></Link> : null}
              {WL.showApi ? <Link withLang to="/features#disklessPxe"><FormattedMessage id="featuresChain.setup6" defaultMessage="Diskless PXE" /></Link> : null}
            </div>
          </div>
          <div className={styles.featuresList}>
            <div className={styles.content}>
              <Link withLang to="/features#liveMonitoring"><FormattedMessage id="featuresChain.manage1" defaultMessage="Real-time Monitoring Data" /></Link>
              <Link withLang to="/features#heatmap"><FormattedMessage id="featuresChain.manage2" defaultMessage="Heatmap" /></Link>
              {WL.showTelegram && <Link to="/features#notifications"><FormattedMessage id="featuresChain.manage3" defaultMessage="Telegram Notifications" /></Link>}
              {WL.showDiscord && <Link to="/features#notifications"><FormattedMessage id="featuresChain.manage4" defaultMessage="Discord Notifications" /></Link>}
              <Link withLang to="/features#ogranizeWorkersFarm"><FormattedMessage id="featuresChain.manage5" defaultMessage="Organize Workers into Farms" /></Link>
              <Link withLang to="/features#supported-miners"><FormattedMessage id="featuresChain.manage6" defaultMessage="Large Number of Miners" /></Link>
              <Link withLang to="/features#sharesEfficiency"><FormattedMessage id="featuresChain.manage7" defaultMessage="Shares Efficiency" /></Link>
              <Link withLang to="/features#statistics"><FormattedMessage id="featuresChain.manage8" defaultMessage="Statistics" /></Link>
              <Link withLang to="/features#remoteConsole"><FormattedMessage id="featuresChain.manage9" defaultMessage="Remote Console" /></Link>
              <Link withLang to="/features#remoteOsUpd"><FormattedMessage id="featuresChain.manage10" defaultMessage="Remote OS Update" /></Link>
              <Link withLang to="/features#vpn"><FormattedMessage id="featuresChain.manage11" defaultMessage="VPN" /></Link>
              <Link withLang to="/features#powerConsumption"><FormattedMessage id="featuresChain.manage12" defaultMessage="Power Consumption" /></Link>
              <Link withLang to="/features#remoteControl"><FormattedMessage id="featuresChain.manage13" defaultMessage="Remote Reboot/Shutdown" /></Link>
              <Link withLang to="/features#schedules"><FormattedMessage id="featuresChain.manage14" defaultMessage="Schedules" /></Link>
              <hr/>
              <Link withLang className={styles.categoryLink} to="/features#fs"><FormattedMessage id="featuresChain.manage15" defaultMessage="Flight Sheets" /></Link>
              <Link withLang to="/features#onTheFlyTuning"><FormattedMessage id="featuresChain.manage16" defaultMessage="On The Fly Tuning" /></Link>
              <Link withLang to="/features#wallets"><FormattedMessage id="featuresChain.manage17" defaultMessage="Wallets" /></Link>
              <Link withLang to="/features#fastPollSwitching"><FormattedMessage id="featuresChain.manage18" defaultMessage="Fast Pool Switching" /></Link>
              <Link withLang to="/features#fastMinerSwitching"><FormattedMessage id="featuresChain.manage19" defaultMessage="Fast Miner Switching" /></Link>
              <Link withLang to="/features#personalConfig"><FormattedMessage id="featuresChain.manage20" defaultMessage="Individual Configs for Each Miner Type" /></Link>
            </div>
          </div>
          <div className={styles.featuresList}>
            <div className={styles.content}>
              <Link withLang to="/features#overclocking"><FormattedMessage id="featuresChain.tuning1" defaultMessage="GPU Overclocking" /></Link>
              <Link withLang to="/features#dualMiners"><FormattedMessage id="featuresChain.tuning2" defaultMessage="Dual Miners" /></Link>
              <Link withLang to="/features#minerTuning"><FormattedMessage id="featuresChain.tuning3" defaultMessage="Miner Tuning" /></Link>
              <Link withLang to="/features#pill"><FormattedMessage id="featuresChain.tuning4" defaultMessage="Pill" /></Link>
              <Link withLang to="/features#autofan"><FormattedMessage id="featuresChain.tuning5" defaultMessage="Autofan" /></Link>
              <Link withLang to="/features#rxBoost"><FormattedMessage id="featuresChain.tuning6" defaultMessage="RX Boost" /></Link>
            </div>
          </div>
          <div className={styles.featuresList}>
            <div className={styles.content}>
              <Link withLang to="/features#cpuMining"><FormattedMessage id="featuresChain.more1" defaultMessage="CPU Mining" /></Link>
              <Link withLang to="/features#customMiner"><FormattedMessage id="featuresChain.more2" defaultMessage="Custom Miner Integration" /></Link>
              <Link withLang to="/features#secureAccess"><FormattedMessage id="featuresChain.more3" defaultMessage="Secure Access" /></Link>
              <Link withLang to="/features#activityLogs"><FormattedMessage id="featuresChain.more4" defaultMessage="Activity Logs" /></Link>
              <Link withLang to="/features#userRightsSettings"><FormattedMessage id="featuresChain.more5" defaultMessage="User Rights Settings" /></Link>
              <Link withLang to="/features#twoFactorAuthentication"><FormattedMessage id="featuresChain.more6" defaultMessage="Two Factor Authentication" /></Link>
              <Link withLang to="/features#controlWallets"><FormattedMessage id="featuresChain.more7" defaultMessage="Control Wallets Ownership" /></Link>
              <Link withLang to="/features#ownershipTransfer"><FormattedMessage id="featuresChain.more8" defaultMessage="Ownership Transfer" /></Link>
              <Link withLang to="/features#multilingualInterface"><FormattedMessage id="featuresChain.more9" defaultMessage="Multilingual Interface" /></Link>
              {(WL.showApi || WL.showWatchdog) && <hr/>}
              {WL.showApi && <Link withLang className={styles.categoryLink} to="/features#api"><FormattedMessage id="featuresChain.more10" defaultMessage="API" /></Link>}
              {WL.showWatchdog && <Link withLang className={styles.categoryLink} to="/features#watchdogs"><FormattedMessage id="featuresChain.more11" defaultMessage="Watchdogs" /></Link>}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default FeaturesChain
