import React from 'react';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
import Img from 'components/Image';
import Link from 'components/Link';
import styles from 'pages/pages.module.css'
import pageStyle from 'pages/features/features.module.css';

const Tuning = () => (
  <section className={cx(styles.content, pageStyle.featureCategory)}>
    <div className={styles.wrapper}>
      <div className={cx(styles.backgroundHeader, pageStyle.backgroundHeader)}>powerful</div>
      <h2 id="tuning">
        <FormattedMessage id="Tuning.title" defaultMessage="Tuning" />
      </h2>
      <div className={pageStyle.feature}>
        <div className={pageStyle.name} id="overclocking">
          <FormattedMessage id="Tuning.header1" defaultMessage="GPU Overclocking" />
        </div>
        <div style={{maxWidth: 320}} className={pageStyle.description}>
          <FormattedMessage id="Tuning.description1" defaultMessage="A wide range of GPU settings to reach their most optimal performance: clock speeds, memory speeds, fan speeds and more" />
        </div>
        <div className={cx(pageStyle.smallScreen, pageStyle.__oc)}>
          <Img src="screens/oc.png" className={ pageStyle.boxShadow } alt="oc image" />
        </div>
      </div>
      <div className={pageStyle.feature}>
        <div className={pageStyle.name} id="dualMiners">
          <FormattedMessage id="Tuning.header2" defaultMessage="Dual Miners" />
        </div>
        <div className={pageStyle.description}>
          <FormattedMessage id="Tuning.description2" defaultMessage="Get more from your mining hardware" />
        </div>
      </div>
      <div className={pageStyle.feature}>
        <div className={pageStyle.name} id="minerTuning">
          <FormattedMessage id="Tuning.header3" defaultMessage="Miner Tuning" />
        </div>
        <div className={pageStyle.description}>
          <FormattedMessage id="Tuning.description3" defaultMessage="Tweak any miner parameters for optimal performance. Create individual or farm-wide configs for each miner" />
        </div>
      </div>
      <div className={pageStyle.feature}>
        <div className={pageStyle.name} id="pill">
          <FormattedMessage id="Tuning.header4" defaultMessage="Pill" />
        </div>
        <div className={pageStyle.description}>
          <FormattedMessage id="Tuning.description4" defaultMessage="Pill increases the profitability while mining on the ethash algorithm. Find out more" />
        </div>
      </div>
      <div className={pageStyle.feature}>
        <div className={pageStyle.name} id="autofan">
          <FormattedMessage id="Tuning.header5" defaultMessage="AutoFan" />
        </div>
        <div className={pageStyle.description}>
          <FormattedMessage id="Tuning.description5" defaultMessage="Enable automatic fan control that will keep your GPUs close to the target temperature" />
        </div>
      </div>
      <div className={pageStyle.feature}>
        <div className={pageStyle.name} id="rxBoost">
          <Link external noRef href="https://forum.hiveos.farm/t/amdmemtweak-read-write-mem-timings/12013">
            <FormattedMessage id="Tuning.header6" defaultMessage="RX Boost" />
          </Link>
        </div>
        <div className={pageStyle.description}>
          <FormattedMessage id="Tuning.description6" defaultMessage="Achieve the best speed when mining on memory-intensive algorithms on AMD video cards" />
        </div>
      </div>
    </div>
  </section>
)

export default Tuning;
