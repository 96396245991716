import React from 'react';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
import Link from 'components/Link';
import styles from 'pages/pages.module.css'
import pageStyle from 'pages/features/features.module.css';
import gs from 'pages/pages.module.css';
import gpuAsic from 'utils/images/screens/gpu-asic.gif'
import WL, { WL_BIGMINER, WL_HIVEOS } from 'constants/whiteLabel';

const Steps = () => (
  <section className={cx(styles.content, pageStyle.featureCategory)}>
    <div className={styles.wrapper}>
      <div className={cx(styles.backgroundHeader, pageStyle.backgroundHeader)}>easy</div>
      <h2 id="setup">
        <FormattedMessage id="Steps.title" defaultMessage="Setup" />
      </h2>

      <div className={pageStyle.feature}>
        <div className={pageStyle.name} id="easyInstall">
          <FormattedMessage id="Steps.header1" defaultMessage="Easy Installation" />
        </div>
        <div className={pageStyle.description}>
          <FormattedMessage id="Steps.description1" defaultMessage="Easily installed on most rig configurations within minutes" />
        </div>
      </div>
      <div className={pageStyle.feature}>
        <div className={pageStyle.name} id="gpuSetup">
          <FormattedMessage id="Steps.header2" defaultMessage="GPU Setup" />
        </div>
        <div className={pageStyle.description}>
          <FormattedMessage
            id="Steps.description2"
            defaultMessage="{nvidiaTitle} family and {amdTitle} family are fully supported and can be runned together on the same worker."
            values={
              {
                nvidiaTitle: (
                  <span className={pageStyle.__nvidia}>
                    <FormattedMessage id="Steps.nvidiaDescription" />
                  </span>
                ),
                amdTitle: (
                  <span className={pageStyle.__amd}>
                    <FormattedMessage id="Steps.amdDescription" />
                  </span>
                ),
              }
            }/>
            {' '}
          <FormattedMessage id="Steps.description3" defaultMessage="Remote vBIOS updates are also supported" />
        </div>
      </div>
      <div className={pageStyle.feature}>
        <div className={pageStyle.name} id="asicSetup">
          <FormattedMessage id="Steps.header3" defaultMessage="ASIC Setup" />
        </div>
        <div className={pageStyle.description}>
          <FormattedMessage id="Steps.description4" defaultMessage="Fully support Bitmain Antminer series:" />
          {
            WL.key === WL_HIVEOS ? (
              <ul>
                <li>Antminer S9*/S9i/S9j/S9k/S9SE/S9 Pro/S9-Hydro/S9(VNISH)/S9(mskminer)/S11</li>
                <li>Antminer S10 (S9 Dual mskminer)</li>
                <li>Antminer S19/S19 Pro/S19+</li>
                <li>Antminer S17/S17 Pro/S17e/S17+</li>
                <li>Antminer T17/T17 Pro/T17e/T17+</li>
                <li>Antminer S15</li>
                <li>Antminer T15</li>
                <li>Antminer L3+/L3++</li>
                <li>Antminer D3/D3(Blissz)</li>
                <li>Antminer DR3</li>
                <li>Antminer A3</li>
                <li>Antminer T9/T9+</li>
                <li>Antminer Z9/Z9-Mini</li>
                <li>Antminer X3</li>
                <li>Antminer X17</li>
                <li>Antminer E3</li>
                <li>Antminer B3</li>
                <li>Antminer S7</li>
                <li>Antminer Z11</li>
                <li>&nbsp;</li>
                <li>AvalonMiner 1047</li>
                <li>AvalonMiner 1066-50</li>
                <li>AvalonMiner 1066Pro-55</li>
                <li>&nbsp;</li>
                <li>DragonMint T1</li>
                <li>&nbsp;</li>
                <li>Innosilicon T3H+, T3+, T3+B, T3S, T2Th+, T2Thf+, T2Thl+, T2Th, T2TZ, T2Tz-30T, T2Thm, T2Thf, T2T+ (32T), T2Ts-26T, T2Ti-25T, T2T-24T</li>
                <li>Innosilicon A10L</li>
                <li>Innosilicon A9/A9+ ZMaster</li>
                <li>Innosilicon D9 DecredMaster</li>
                <li>Innosilicon S11 SiaMaster</li>
                <li>Innosilicon T3 BTCMiner</li>
                <li>Innosilicon A5/A8 (<FormattedMessage id="Steps.needTest" defaultMessage="need test" />)</li>
                <li>&nbsp;</li>
                <li>HashAltCoin Blackminer F1, F1+, F1-MINI</li>
                <li>HashAltCoin Blackminer F1-Ultra, F1-MINI+</li>
                <li>&nbsp;</li>
                <li>Todek C1, C1 PRO</li>
                <li>&nbsp;</li>
                <li>Whatsminer M20S</li>
                <li>Whatsminer M21S</li>
                <li>Whatsminer M29</li>
                <li>Whatsminer M30S</li>
                <li>Whatsminer M31S</li>
                <li>Whatsminer M31S+</li>
                <li>&nbsp;</li>
                <li>Zig Z1/Z1+</li>
              </ul>
            ) : null
          }

          {
            WL.key === WL_BIGMINER ? (
              <ul>
                <li>Antminer S9*/S9i/S9j/S9k/S9SE/S9-Hydro/S9(VNISH)/S9</li>
                <li>Antminer S17/S17 Pro/S17e/S17+</li>
                <li>Antminer T17/T17 Pro/T17e/T17+</li>
                <li>Antminer L3+/L3++</li>
              </ul>
            ) : null
          }
        </div>
        <div className={cx(pageStyle.smallScreen, pageStyle.__gpuAsic)}>
          <img alt="gpu asic" src={gpuAsic} className={cx(pageStyle.boxShadow, pageStyle.boxShadow)}/>
        </div>
      </div>
      <div className={pageStyle.feature}>
        <div className={pageStyle.name} id="workersBulk">
          <FormattedMessage id="Steps.header4" defaultMessage="Workers Bulk Installation" />
        </div>
        <div className={pageStyle.description}>
          <FormattedMessage id="Steps.description5" defaultMessage="You can easily bulk install the OS on your rigs using our " />
          <Link external noRef href={WL.getRegisterLink()} className={gs.link}><FormattedMessage id="Steps.link1" defaultMessage="Drive Flashing Utility" /></Link>
        </div>
      </div>
      {
        WL.showApi ? (
          <div className={pageStyle.feature}>
            <div className={pageStyle.name} id="deployPxe">
              <Link external noRef href="https://forum.hiveos.farm/t/hive-os-deploy-pxe/12444">
                <FormattedMessage id="Steps.header5" defaultMessage="Deploy PXE" />
              </Link>
            </div>
            <div className={pageStyle.description}>
              <FormattedMessage id="Steps.description6_start" defaultMessage="Need to deploy hundreds or thousands of rigs with HiveOS?" />
              <br />
              <FormattedMessage id="Steps.description6_use" defaultMessage="Use " />{' '}
              <Link external noRef href="https://forum.hiveos.farm/t/hive-os-deploy-pxe/12444" className={gs.link}><FormattedMessage id="Steps.link2" defaultMessage="Deploy PXE " /></Link>{' '}
              <FormattedMessage id="Steps.description6_end" defaultMessage="for deployment over the network, gaining complete control over the process and significantly reducing deployment time" />
            </div>
          </div>
        ) : null
      }
      {
        WL.showApi ? (
          <div className={pageStyle.feature}>
            <div className={pageStyle.name} id="disklessPxe">
              <Link external noRef href="https://forum.hiveos.farm/t/hive-os-diskless-pxe/12319">
                <FormattedMessage id="Steps.header6" defaultMessage="Hive OS Diskless PXE" />
              </Link>
            </div>
            <div className={pageStyle.description}>
              <FormattedMessage id="Steps.description7_start" defaultMessage="Solution for diskless rigs and server to boot them over the network" />
              <br />
              <Link external noRef href="https://forum.hiveos.farm/t/hive-os-diskless-pxe/12319" className={gs.link}><FormattedMessage id="Steps.link3" defaultMessage="Hive OS Diskless PXE" /></Link>
              <FormattedMessage id="Steps.description7_end" defaultMessage=" will allow you to reduce the cost of your mining investment by saving on disk media" />
            </div>
          </div>
        ) :null
      }
    </div>
  </section>
)

export default Steps;
