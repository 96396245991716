import React from 'react';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
import styles from 'pages/pages.module.css'
import pageStyle from 'pages/features/features.module.css';
import Img from 'components/Image';
import Link from 'components/Link';
import WL from 'constants/whiteLabel';

import shares from 'utils/images/screens/shares.gif'
import realtimeAlgo from 'utils/images/screens/realtime-algo.gif'
import realtimeTempfan from 'utils/images/screens/realtime-tempfan.gif'
import update from 'utils/images/screens/update.gif'
import walletCreate from 'utils/images/screens/wallet-create.gif'
import schedules from 'utils/images/screens/schedules.gif'

const Manage = () => (
  [
    <section key="Manage" className={cx(styles.content, pageStyle.featureCategory)}>
      <div className={styles.wrapper}>
        <div className={cx(styles.backgroundHeader, pageStyle.backgroundHeader)}>simple</div>
        <h2 id="manage">
          <FormattedMessage id="Manage.title1" defaultMessage="Manage" />
        </h2>
        <div style={{overflow:'visible'}} className={pageStyle.feature}>
          <div className={pageStyle.name} id="liveMonitoring">
            <FormattedMessage id="Manage.header1" defaultMessage="Real-time Monitoring Data" />
          </div>
          <div className={pageStyle.description}>
            <FormattedMessage id="Manage.description1" defaultMessage="Real-time monitoring of your workers stats with regular refresh intervals without the need to reload the page" />
          </div>
          <div className={cx(pageStyle.smallScreen, pageStyle.__realtime)}>
            <div className={pageStyle.__algo}>
              <img alt="algo" src={realtimeAlgo} className={pageStyle.boxShadow} />
            </div>
            <div className={pageStyle.__tempfan}>
              <img alt="tempfan" src={realtimeTempfan} className={pageStyle.boxShadow} />
            </div>
          </div>
        </div>
        <div className={pageStyle.feature}>
          <div className={pageStyle.name} id="heatmap">
            <FormattedMessage id="Manage.header2" defaultMessage="Heatmap" />
          </div>
          <div className={pageStyle.description}>
            <p>
              <FormattedMessage id="Manage.description2" defaultMessage="Lets you see the temperatures across all your workers and gives you a warning in case of critical temperature" />
            </p>
            <p>
              <FormattedMessage id="Manage.description3" defaultMessage="The watchdog can perform a system reboot if a critical temperature has reached" />
            </p>
          </div>
          <div className={cx(pageStyle.smallScreen, pageStyle.__heatmap)}>
            <Img src="screens/heatmap.png" className={pageStyle.boxShadow} alt="heatmap"/>
          </div>
        </div>
        {
          WL.showTelegram ? (
            <div className={pageStyle.feature}>
              <div className={pageStyle.name} id="notifications">
                <FormattedMessage id="Manage.header3" defaultMessage="Telegram or Discord Notifications" />
              </div>
              <div className={pageStyle.description}>
                <FormattedMessage id="Manage.description4" defaultMessage="Stay updated on your farm’s performance through Telegram" />
              </div>
            </div>
          ) : null
        }
        <div className={pageStyle.feature}>
          <div className={pageStyle.name} id="ogranizeWorkersFarm">
            <FormattedMessage id="Manage.header4" defaultMessage="Organize Workers into Farms" />
          </div>
          <div className={pageStyle.description}>
            <FormattedMessage id="Manage.description5" defaultMessage="Rigs can be split and categorized between their owners, locations or whatever by organizing them into the farms with custom access rights and tags" />
          </div>
        </div>
        <div className={pageStyle.feature}>
          <div className={pageStyle.name} id="sharesEfficiency">
            <FormattedMessage id="Manage.header5" defaultMessage="Shares Efficiency" />
          </div>
          <div className={pageStyle.description}>
            <FormattedMessage id="Manage.description6" defaultMessage="Сontrol your workers shares efficiency by viewing average accepted, rejected or invalid submitted shares" />
          </div>
          <div className={cx(pageStyle.smallScreen, pageStyle.__shares)}>
            <img alt="shares" src={shares} className={cx(pageStyle.boxShadow, pageStyle.boxShadow)}/>
          </div>
        </div>
        <div className={pageStyle.feature}>
          <div className={pageStyle.name} id="statistics">
            <FormattedMessage id="Manage.header6" defaultMessage="Statistics" />
          </div>
          <div className={pageStyle.description}>
            <FormattedMessage id="Manage.description7" defaultMessage="See the stats on how your worker performs with info on different algorithms, miners, temperatures and fan speeds" />
          </div>
        </div>
        <div className={cx(pageStyle.feature, pageStyle.illustration)}>
          <div className={pageStyle.name} id="remoteConsole">
            <FormattedMessage id="Manage.header7" defaultMessage="Remote Console" />
          </div>
          <div className={pageStyle.description}>
            <FormattedMessage id="Manage.description8" defaultMessage="Send commands via remote console to your workers via SSH, ShellInABox, VNC and Teleconsole" />
          </div>
          <div className={pageStyle.smallScreen}>
            <div className={styles.whiteSpace}>
            </div>
            <Img src="remote-console-slide1.png" alt="remote console" />
          </div>
        </div>
        <div className={pageStyle.feature}>
          <div className={pageStyle.name} id="remoteOsUpd">
            <FormattedMessage id="Manage.header8" defaultMessage="Remote OS Update" />
          </div>
          <div className={pageStyle.description}>
            <FormattedMessage id="Manage.description9" defaultMessage="Keep your worker updated to the latest version of Hive OS" />
          </div>
          <div className={cx(pageStyle.smallScreen, pageStyle.__update)}>
            <img alt="update" src={update} className={pageStyle.boxShadow}/>
          </div>

        </div>
        <div className={pageStyle.feature}>
          <div className={pageStyle.name} id="vpn">
            <FormattedMessage id="Manage.header9" defaultMessage="VPN" />
          </div>
          <div className={pageStyle.description}>
            <FormattedMessage id="Manage.description10" defaultMessage="Integrated VPN setting for IP masking to avoid creating IP address whitelist" />
          </div>
        </div>
        <div className={pageStyle.feature}>
          <div className={pageStyle.name} id="powerConsumption">
            <FormattedMessage id="Manage.header10" defaultMessage="Power Consumption" />
          </div>
          <div className={pageStyle.description}>
            <FormattedMessage id="Manage.description11" defaultMessage="Statistics of your GPU’s power consumption farm-wide and each worker" />
          </div>
        </div>
        <div className={pageStyle.feature}>
          <div className={pageStyle.name} id="remoteControl">
            <FormattedMessage id="Manage.header11" defaultMessage="Remote Reboot/Shutdown" />
          </div>
          <div className={pageStyle.description}>
            <FormattedMessage id="Manage.description12" defaultMessage="Perform reboots or shutdown your workers remotely" />
          </div>
        </div>
        <div className={pageStyle.feature}>
          <div className={pageStyle.name} id="schedules">
            <Link external href="https://www.youtube.com/watch?v=adKdAoul_N8">
              <FormattedMessage id="Manage.header12" defaultMessage="Schedules" />
            </Link>
          </div>
          <div className={pageStyle.description}>
            <FormattedMessage id="Manage.description13" defaultMessage="Manage workers on a schedule. Create events to launch flight sheets or execute other various commands" />
          </div>
          <div className={cx(pageStyle.smallScreen, pageStyle.__img)}>
            <img alt="schedules" src={schedules} className={pageStyle.boxShadow} />
          </div>
        </div>
      </div>
    </section>,

    <section key="FS" className={cx(styles.content, pageStyle.featureCategory)}>
      <div className={styles.wrapper}>
        <div className={cx(styles.backgroundHeader, pageStyle.backgroundHeader)}>rocket</div>
        <h2 id="fs">
          <FormattedMessage id="Manage.title2" defaultMessage="Flight Sheets" />
        </h2>
        <div className={pageStyle.feature}>
          <div className={pageStyle.name} id="onTheFlyTuning">
            <FormattedMessage id="Manage.header13" defaultMessage="On The Fly Tuning" />
          </div>
          <div className={pageStyle.description}>
            <FormattedMessage id="Manage.description14" defaultMessage="Flight Sheets let you tweak worker settings with different wallet, pool, and miners combinations and applying them lightning-fast to any number of workers" />
          </div>
        </div>
        <div className={pageStyle.feature}>
          <div className={pageStyle.name} id="wallets">
            <FormattedMessage id="Manage.header14" defaultMessage="Wallets" />
          </div>
          <div className={pageStyle.description}>
            <FormattedMessage id="Manage.description15" defaultMessage="Adding wallets is as simple as selecting a coin and entering your address." />
          </div>
          <div className={cx(pageStyle.smallScreen, pageStyle.__wallet)}>
            <img alt="wallet create" src={walletCreate} className={pageStyle.boxShadow} />
          </div>
        </div>
        <div className={pageStyle.feature}>
          <div className={pageStyle.name} id="fastPollSwitching">
            <FormattedMessage id="Manage.header15" defaultMessage="{fast} Pool Switching" values={{ fast: <span className={pageStyle.__fast}><FormattedMessage id="Manage.fast" defaultMessage="Fast" /></span> }} />
          </div>
          <div className={pageStyle.description}>
            <FormattedMessage id="Manage.description16" defaultMessage="Easily switch between pools and pool servers with our pre-made configurations" />
          </div>
        </div>
        <div className={pageStyle.feature}>
          <div className={pageStyle.name} id="fastMinerSwitching">
            <FormattedMessage id="Manage.header16" defaultMessage="{fast} Miner Switching" values={{ fast: <span className={pageStyle.__fast}><FormattedMessage id="Manage.fast" defaultMessage="Fast" /></span> }} />
          </div>
          <div className={pageStyle.description}>
            <FormattedMessage id="Manage.description17" defaultMessage="Detailed interface for each of the supported miners helps you configure your own miner settings for every flight sheet" />
          </div>
        </div>
        <div className={pageStyle.feature}>
          <div className={pageStyle.name} id="personalConfig">
            <FormattedMessage id="Manage.header17" defaultMessage="Individual Configs for Each Miner" />
          </div>
          <div className={pageStyle.description}>
            <FormattedMessage id="Manage.description18" defaultMessage="Override your farm settings on each worker individually with worker-specific configs" />
          </div>
        </div>
      </div>
    </section>
  ]
);

export default Manage;
