import React from 'react';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
import gs from 'pages/pages.module.css';
import Img from 'components/Image';
import Link from 'components/Link';
import WL, { WL_BIGMINER, WL_HIVEOS } from 'constants/whiteLabel';
import minersList from 'utils/images/screens/miners-list.gif';
import changeAccess from 'utils/images/screens/change-access.gif';
import s from 'pages/features/features.module.css';

const More = () => (
    [
        <section key="moreInfo" className={cx(gs.content, s.featureCategory)}>
            <div className={gs.wrapper}>
                <div className={cx(gs.backgroundHeader, s.backgroundHeader)}>even</div>
                <h2 id="more">
                    <FormattedMessage id="More.title1" defaultMessage="More" />
                </h2>
                <div className={s.feature}>
                    <div className={s.name} id="cpuMining">
                        <FormattedMessage id="More.header1" defaultMessage="CPU Mining" />
                    </div>
                    <div className={s.description}>
                        <FormattedMessage id="More.description1" defaultMessage="Support for CPU mining in case you're keen on making the most out of your mining setup" />
                    </div>
                </div>
                <div className={s.feature}>
                    <div className={s.name} id="customMiner">
                        <FormattedMessage id="More.header2" defaultMessage="Custom Miner" />
                    </div>
                    <div className={s.description}>
                        <FormattedMessage id="More.description2" defaultMessage="Add any miner that's not currently integrated into Hive OS distribution" />
                    </div>
                    <div className={cx(s.smallScreen, s.__miners)}>
                        <img alt="miners list" src={minersList}/>
                    </div>
                </div>
                <div className={s.feature}>
                    <div className={s.name} id="secureAccess">
                        <FormattedMessage id="More.header3" defaultMessage="Secure Access" />
                    </div>
                    <div className={s.description}>
                        <FormattedMessage id="More.description3" defaultMessage="Includes whitelisting for IP addresses and secure remote access via SSH" />
                    </div>
                </div>
                <div className={s.feature}>
                    <div className={s.name} id="activityLogs">
                        <FormattedMessage id="More.header4" defaultMessage="Activity Logs" />
                    </div>
                    <div className={s.description}>
                        <FormattedMessage id="More.description4" defaultMessage="Stay informed on all workers and farm changes done by you and others" />
                    </div>
                </div>
                <div className={s.feature}>
                    <div className={s.name} id="userRightsSettings">
                        <FormattedMessage id="More.header5" defaultMessage="User Rights Settings" />
                    </div>
                    <div className={s.description}>
                        <FormattedMessage id="More.description5" defaultMessage="Give Full Access privileges to your admins or read-only Monitor access privileges to your technical staff that does simple maintenance and monitoring" />
                    </div>
                    <div className={cx(s.smallScreen, s.__access)}>
                        <img alt="change access" src={changeAccess}/>
                    </div>
                </div>
                <div className={s.feature}>
                    <div className={s.name} id="twoFactorAuthentication">
                        <FormattedMessage id="More.header6" defaultMessage="Two Factor Authentication (2FA)" />
                    </div>
                    <div className={s.description}>
                        <FormattedMessage id="More.description6" defaultMessage="Give your account an extra layer of security" />
                    </div>
                </div>
                <div className={s.feature}>
                    <div className={s.name} id="ownershipTransfer">
                        <FormattedMessage id="More.header7" defaultMessage="Ownership Transfer" />
                    </div>
                    <div className={s.description}>
                        <FormattedMessage id="More.description7" defaultMessage="Transfer workers individually or even entire Farms to other user's accounts" />
                    </div>
                </div>
                <div className={s.feature}>
                    <div className={s.name} id="multilingualInterface">
                        <FormattedMessage id="More.header8" defaultMessage="Multilingual Interface" />
                    </div>
                    <div className={s.description}>
                        <FormattedMessage id="More.description8" defaultMessage="Now even easier to use" />
                    </div>
                    <div className={cx(s.smallScreen, s.__multilang)}>
                        <Img src="screens/multilang.png" alt="multilang"/>
                    </div>
                </div>
            </div>
        </section>,

        <section key="supportMiners" className={cx(gs.content, s.supportedMiners)}>
            <div className={gs.wrapper}>
                <h2 id="supported-miners">
                    <FormattedMessage id="More.title2" defaultMessage="Supported Miners" />
                </h2>
                <div className={s.subheader}>
                    <FormattedMessage
                      id="More.title2_caption1"
                      defaultMessage="With {customMinerLink} feature add any miner of your preference"
                      values={{ customMinerLink: <Link external noRef href="https://forum.hiveos.farm/t/custom-miner-integration/4521" className={gs.link}><FormattedMessage id="More.customMiner" defaultMessage="Custom Miner" /></Link> }}
                    />
                </div>
                <div className={s.supportedMinersWrapper}>
                    <div className={s.supportedMinersColumn}>
                      <p>Beam CUDA/OpenCL Miners</p>
                      <p>Bminer</p>
                      <p>BzMiner <span className={s.thin}><FormattedMessage id="soon" defaultMessage="soon" /></span></p>
                      <p>Cast-XMR</p>
                      <p>CCminer <span className={s.thin}><FormattedMessage id="More.availableForks" defaultMessage="forks available" /></span></p>
                      <p>CKB-miner</p>
                      <p>Claymore&apos;s CryptoNote AMD GPU</p>
                      <p>Claymore&apos;s Dual Ethereum</p>
                      <p>Claymore&apos;s ZCash AMD GPU</p>
                      <p>Cortex-miner</p>
                      <p>cpuminer-opt <span className={s.thin}><FormattedMessage id="More.availableForks" defaultMessage="forks available" /></span></p>
                      <p>CryptoDredge </p>
                      <p>DamoMiner</p>
                      <p>Dstm&apos;s ZCash CUDA</p>
                      <p>EggMinerGpu</p>
                      <p>Ethminer <span className={s.thin}><FormattedMessage id="More.availableForks" defaultMessage="forks available" /></span></p>
                      <p>EWBF CUDA Equihash Miner</p>
                      <p>FinMiner</p>
                    </div>
                    <div className={s.supportedMinersColumn}>
                      <p>FiroMiner <span className={s.thin}><FormattedMessage id="soon" defaultMessage="soon" /></span></p>
                      <p>GMiner</p>
                      <p>Grin Miner</p>
                      <p>GrinGoldMiner</p>
                      <p>GrinPro</p>
                      <p>HellMiner</p>
                      <p>HSPMinerAE</p>
                      <p>KawPowMiner <span className={s.thin}><FormattedMessage id="as ethminer fork" defaultMessage="as ethminer fork" /></span></p>
                      <p>KBMiner </p>
                      <p>lolMiner</p>
                      <p>miniZ</p>
                      <p>NanoMiner / FinMiner</p>
                      <p>NBMiner</p>
                      <p>NhEqMiner</p>
                      <p>Noncepool AMD/Nvidia miner</p>
                      <p>NoncerPro CUDA/OpenCL</p>
                      <p>nq-miner</p>
                      <p>NSFminer <span className={s.thin}><FormattedMessage id="as ethminer fork" defaultMessage="as ethminer fork" /></span></p>
                    </div>
                    <div className={s.supportedMinersColumn}>
                      <p>PhoenixMiner</p>
                      <p>RandomHash miner</p>
                      <p>SGminer <span className={s.thin}><FormattedMessage id="More.availableForks" defaultMessage="forks available" /></span></p>
                      <p>Spark Miner</p>
                      <p>Sushi-Miner CUDA/OpenCL</p>
                      <p>TBMiner <span className={s.thin}><FormattedMessage id="soon" defaultMessage="soon" /></span></p>
                      <p>TeamRedMiner</p>
                      <p>TT Miner</p>
                      <p>VerthashMiner</p>
                      <p>VioletMiner</p>
                      <p>T-Rex</p>
                      <p>WildRig-Multi</p>
                      <p>XMRig -AMD/-Nvidia <span className={s.thin}><FormattedMessage id="More.availableForks" defaultMessage="forks available" /></span></p>
                      <p>XMR-Stak <span className={s.thin}><FormattedMessage id="More.availableForks" defaultMessage="forks available" /></span></p>
                      <p>XPMminer</p>
                      <p>zjazz CUDA miner</p>
                    </div>
                </div>
            </div>
        </section>,

        WL.showApi ? <section key="api" className={cx(gs.content, s.featureCategory)}>
            <div className={gs.backgroundHeader}>powerful</div>
            <div className={gs.wrapper} id="api">
                <h2>API</h2>
                <div className={gs.subheader}><Link external noRef className={cx(s.api, gs.link)} href="https://forum.hiveos.farm/t/hive-api-v2/4490" target="_self">Hive 2.0 API</Link> – <FormattedMessage id="More.title2_caption2" defaultMessage="Dive deeper into your data with great flexibility" /></div>

            </div>
        </section> : null,

        WL.showWatchdog ? <section key="watchdogs" className={cx(gs.content, s.supportedMiners)}>
            <div className={gs.wrapper}>
                <h2 id="watchdogs">
                    <FormattedMessage id="More.title3" defaultMessage="Watchdogs support" />
                </h2>
                <div className={gs.subheader}>
                    <FormattedMessage id="More.caption3" defaultMessage="Other watchdogs support can be added on request" />
                </div>
                <div className={s.supportedMinersWrapper}>
                    <div className={cx(s.supportedMinersColumn, s.watchDogsColumn)}>
                        <Link external href="http://watchdog.in.ua/" className={gs.link}>WatchdogInUa</Link>
                        <div>
                            <b><FormattedMessage id="More.chineseWatchdogs" defaultMessage="Chinese Watchdogs" /></b>
                            <span className={gs.thin}> (QinHeng Electronics HL-340). <FormattedMessage id="More.caption4" defaultMessage="Those that have orange blocks, find them on Ali and Ebay." /></span>
                        </div>
                    </div>
                    <div className={cx(s.supportedMinersColumn,s.watchDogsColumn)}>
                        <Link external href="https://esonics.com/catalog/storozhevyye-taymery/" className={gs.link}>Esonics</Link>
                        <Link external noRef href="https://forum.hiveos.farm/discussion/694/simplerigresetter-ssrv2-watchdog" className={gs.link}>
                            SimpleRigResetter SSRv2
                        </Link>
                        <Link external href="https://open-dev.ru/watchdog" className={gs.link}>OpenDev</Link>
                    </div>
                </div>
            </div>
        </section> : null,

        <section key="hardware" className={cx(gs.content, s.testedHardwareContent, s.featureCategory)}>
            <div className={gs.backgroundHeader}>tested</div>
            <div className={gs.wrapper}>
                <h2>
                    <FormattedMessage id="More.title4" defaultMessage="Hardware" />
                </h2>
                <div className={s.testedHardwareWrapper}>
                    <div className={s.testedHardware}>
                        <h3>Nvidia</h3>
                        <p>
                            <FormattedMessage
                              id="More.nvidia_description"
                              defaultMessage="Nvidia GeForce 10xx series family (1050, 1050ti, 1060, 1070, 1070ti, 1080, 1080ti, P106-090, P106-100, P104-100, P102-100), 16хх series (1650, 1650 Super, 1660, 1660 Super, 1660ti), 20xx (2060, 2060 Super, 2070, 2070 Super, 2080, 2080 Super, 2080ti), 30xx series (3060ti, 3070, 3080, 3090) are fully supported with overclocking"
                              values={{ break: <br /> }}
                            />
                        </p>
                    </div>
                    <div className={s.testedHardware}>
                        <h3>AMD</h3>
                        <p>
                            <FormattedMessage
                              id="More.amd_description"
                              defaultMessage="AMD Radeon RX series family (460, 470, 480, 550, 560, 570, 580, 590), RX Vega 56 and 64, Radeon VII, 5500XT, 5600 XT, 5700, 5700 XT are fully supported with overclocking"
                              values={{ break: <br /> }}
                            />
                        </p>
                    </div>

                    {
                        WL.key === WL_HIVEOS ? (
                            <div className={s.testedHardware}>
                                <h3>ASIC</h3>
                                <p>Bitmain Antminer series S9/S9i/S9j/S9k/S9SE/S9 Pro/S9-Hydro/S9(VNISH)/S9(mskminer)/S10 (mskminer)/S11, S17/S17 Pro/S17e/S17+, S19/S19 Pro/S19+, T17/T17 Pro/T17e/T17+, S15, T15, L3+/L3+, D3/D3(Blissz), DR3, A3, T9/T9+, Z9/Z9-Mini, X3, X17, E3, B3, S7, Z11</p>
                                <p>Innosilicon T3H+, T3+, T3+B, T3S, T2Th+, T2Thf+, T2Thl+, T2Th, T2TZ, T2Tz-30T, T2Thm, T2Thf, T2T+ (32T), T2Ts-26T, T2Ti-25T, T2T-24T, A9/A9+ ZMaster, A10L, D9 DecredMaster, S11 SiaMaster, T3 BTCMiner, A5/A8 (<FormattedMessage id="Steps.needTest" defaultMessage="need test" />)</p>
                                <p>AvalonMiner 1047, 1066-50, 1066Pro-55</p>
                                <p>DragonMint T1</p>
                                <p>HashAltCoin Blackminer F1, F1+, F1-MINI, F1-Ultra, F1-MINI+</p>
                                <p>Todek C1/C1 Pro</p>
                                <p>Whatsminer M20S, M21S, M29, M30S, M31S, M31S+</p>
                                <p>Zig Z1/Z1+</p>
                            </div>
                        ) : null
                    }
                    {
                        WL.key === WL_BIGMINER ? (
                            <div className={s.testedHardware}>
                                <h3>ASIC</h3>
                                <p>Bitmain Antminer series S9/S9i/S9j/S9k/S9SE/S9-Hydro/S9(VNISH), S17/S17 Pro/S17e/S17+, L3+/L3+</p>
                            </div>
                        ) : null
                    }
                </div>
            </div>
        </section>
    ]
)

export default More;
