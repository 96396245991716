import React from 'react';
import { FormattedMessage } from 'react-intl';
import _get from 'lodash/get';
import Layout from 'components/layout';

import Hero from 'components/Layout/Hero';
import FeaturesChain from 'components/features-chain/features-chain';

import Steps from 'components/Features/Steps';
import Manage from 'components/Features/Manage';
import Tuning from 'components/Features/Tuning';
import More from 'components/Features/More';
import GetStarted from 'components/GetStarted';
import Bottom from 'components/Bottom/Bottom';

class SecondPage extends React.Component {

    state = {
        pinHeader: false
    }

    componentDidMount() {
        window.addEventListener("scroll", this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.updateDimensions);
    }

    updateDimensions = (e) => {

        let scrollTop = e.path[1].scrollY;

        if ( scrollTop >= 1100 && !this.state.pinHeader) {
            this.setState({
                pinHeader: true
            });
        } else if ( scrollTop < 1100 && this.state.pinHeader) {
            this.setState({
                pinHeader: false
            });
        }

    }

    render() {
        return (
            <Layout page="features" lang={_get(this.props, 'pageContext.lang', 'en')}>
              <Hero
                headerIntl={<FormattedMessage id="features.features" defaultMessage="Features" />}
                subHeaderIntl={<FormattedMessage id="features.caption" defaultMessage="We are constantly updating and adding new features, driven by mining community needs" />}
              >
                <FeaturesChain pinHeader={this.state.pinHeader}/>
              </Hero>
              
              <div>
                  
                <Steps />

                <Manage />

                <Tuning />

                <More />

                <GetStarted/>

                <Bottom/>

              </div>

            </Layout>
        )
    }

}


export default SecondPage
